import React from "react";
import styles from "./../styles/footer.module.scss";
import footerIcon from "./../images/iconRecyclingFooter.svg";
import fbIcon from "./../images/fbIcon.svg";
import igIcon from "./../images/igIcon.svg";
import NewsletterFooter from "./Sections/NewsletterFooter";
import useDeviceDetect from "./../hooks/useDevice";

export default function Footer({ isHideLanding }) {
    const { isMobile } = useDeviceDetect();
    return (
        <div className={styles.container}>
            {isMobile ? (
                <>
                    <div className={styles.mobileFooterContainer}>
                        <div className={styles.recycleContainer}>
                            <img
                                alt="icon"
                                src={footerIcon}
                                className={styles.recycle27Icon}
                            />
                        </div>
                        <div className={styles.socialContainer}>
                            <h2>Seguinos en</h2>
                            <div className={styles.socialIconContainer}>
                                <a
                                    href="https://www.facebook.com/cerveza27"
                                    target="_blank"
                                    onClick={() => {
                                        window.dataLayer.push({
                                            event: "GAEvent",
                                            event_category: "Social",
                                            event_action: "Click",
                                            event_label: `https://www.facebook.com/cerveza27`,
                                            interaction: true,
                                            component_name: "boton_facebook",
                                            element_text: "Facebook",
                                        });
                                    }}
                                >
                                    <img alt="icon" src={fbIcon} />
                                </a>
                                <a
                                    href="https://www.instagram.com/cerveza27/"
                                    target="_blank"
                                    onClick={() => {
                                        window.dataLayer.push({
                                            event: "GAEvent",
                                            event_category: "Social",
                                            event_action: "Click",
                                            event_label: `https://www.instagram.com/cerveza27/`,
                                            interaction: true,
                                            component_name: "boton_instagram",
                                            element_text: "Instagram",
                                        });
                                    }}
                                >
                                    <img alt="icon" src={igIcon} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={styles.mainContainer}>
                        <NewsletterFooter isHideLanding={isHideLanding} />
                        <div className={styles.infoContainer}>
                            <div className={styles.fisrtText}>
                                <p className={styles.firstP}>
                                    BEBER CON MODERACIÓN. PROHIBIDA LA VENTA A
                                    MENORES DE 18 AÑOS.
                                </p>
                                <p className={styles.boldP}>
                                    NO COMPARTIR ESTE CONTENIDO CON MENORES.
                                </p>
                            </div>
                            <p className={styles.secondP}>
                                ©2021VEINTISIETECERVEZA. TODOS LOS DERECHOS
                                RESERVADOS.
                            </p>
                        </div>
                        <div className={styles.termsContainer}>
                            <a
                                href="https://www.cerveceriaymalteriaquilmes.com/"
                                target="_blank"
                                onClick={() => {
                                    window.dataLayer.push({
                                        event: "GAEvent",
                                        event_category: "Footer",
                                        event_action: "Click",
                                        event_label: `https://www.cerveceriaymalteriaquilmes.com/`,
                                        interaction: true,
                                        component_name: "",
                                        element_text: "",
                                    });
                                }}
                            >
                                INFORMACIÓN OFICIAL DE LA COMPAÑIA
                            </a>
                            <a
                                href="/TerminosYCondiciones27.pdf"
                                target="_blank"
                                onClick={() => {
                                    window.dataLayer.push({
                                        event: "GAEvent",
                                        event_category: "Footer",
                                        event_action: "Click",
                                        event_label: `${window.location.origin}/TerminosYCondiciones27.pdf`,
                                        interaction: true,
                                        component_name: "",
                                        element_text: "",
                                    });
                                }}
                            >
                                TÉRMINOS Y CONDICIONES
                            </a>
                            <a
                                href="/PoliticasPrivacidad27.pdf"
                                target="_blank"
                                onClick={() => {
                                    window.dataLayer.push({
                                        event: "GAEvent",
                                        event_category: "Footer",
                                        event_action: "Click",
                                        event_label: `${window.location.origin}/PoliticasPrivacidad27.pdf`,
                                        interaction: true,
                                        component_name: "",
                                        element_text: "",
                                    });
                                }}
                            >
                                POLÍTICAS DE PRIVACIDAD
                            </a>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.recycleContainer}>
                        <img
                            alt="icon"
                            src={footerIcon}
                            className={styles.recycle27Icon}
                        />
                    </div>
                    <div className={styles.mainContainer}>
                        <NewsletterFooter isHideLanding={isHideLanding} />
                        <div className={styles.infoContainer}>
                            <div className={styles.fisrtText}>
                                <p className={styles.firstP}>
                                    BEBER CON MODERACIÓN. PROHIBIDA LA VENTA A
                                    MENORES DE 18 AÑOS.
                                    <p className={styles.boldP}>
                                        &nbsp;NO COMPARTIR ESTE CONTENIDO CON
                                        MENORES.
                                    </p>
                                </p>
                            </div>
                            <p className={styles.secondP}>
                                ©2021VEINTISIETECERVEZA. TODOS LOS DERECHOS
                                RESERVADOS.
                            </p>
                        </div>
                        <div className={styles.termsContainer}>
                            <a
                                href="https://www.cerveceriaymalteriaquilmes.com/"
                                target="_blank"
                                onClick={() => {
                                    window.dataLayer.push({
                                        event: "GAEvent",
                                        event_category: "Footer",
                                        event_action: "Click",
                                        event_label: `https://www.cerveceriaymalteriaquilmes.com/`,
                                        interaction: true,
                                        component_name: "",
                                        element_text: "",
                                    });
                                }}
                            >
                                INFORMACIÓN OFICIAL DE LA COMPAÑIA
                            </a>
                            <a
                                href="/TerminosYCondiciones27.pdf"
                                target="_blank"
                                onClick={() => {
                                    window.dataLayer.push({
                                        event: "GAEvent",
                                        event_category: "Footer",
                                        event_action: "Click",
                                        event_label: `${window.location.origin}/TerminosYCondiciones27.pdf`,
                                        interaction: true,
                                        component_name: "",
                                        element_text: "",
                                    });
                                }}
                            >
                                TÉRMINOS Y CONDICIONES
                            </a>
                            <a
                                href="/PoliticasPrivacidad27.pdf"
                                target="_blank"
                                onClick={() => {
                                    window.dataLayer.push({
                                        event: "GAEvent",
                                        event_category: "Footer",
                                        event_action: "Click",
                                        event_label: `${window.location.origin}/PoliticasPrivacidad27.pdf`,
                                        interaction: true,
                                        component_name: "",
                                        element_text: "",
                                    });
                                }}
                            >
                                POLÍTICAS DE PRIVACIDAD
                            </a>
                        </div>
                    </div>
                    <div className={styles.socialContainer}>
                        <h2>Seguinos en</h2>
                        <div className={styles.socialIconContainer}>
                            <a
                                href="https://www.facebook.com/cerveza27"
                                target="_blank"
                                onClick={() => {
                                    window.dataLayer.push({
                                        event: "GAEvent",
                                        event_category: "Social",
                                        event_action: "Click",
                                        event_label: `https://www.facebook.com/cerveza27`,
                                        interaction: true,
                                        component_name: "boton_facebook",
                                        element_text: "Facebook",
                                    });
                                }}
                            >
                                <img alt="icon" src={fbIcon} />
                            </a>
                            <a
                                href="https://www.instagram.com/cerveza27/"
                                target="_blank"
                                onClick={() => {
                                    window.dataLayer.push({
                                        event: "GAEvent",
                                        event_category: "Social",
                                        event_action: "Click",
                                        event_label: `https://www.instagram.com/cerveza27/`,
                                        interaction: true,
                                        component_name: "boton_instagram",
                                        element_text: "Instagram",
                                    });
                                }}
                            >
                                <img alt="icon" src={igIcon} />
                            </a>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
