import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import styles from "./../../styles/footer.module.scss";
import * as Yup from "yup";
import SubmitIcon from "./../../images/footerSubmitIcon.svg";
import CheckboxFooter from "../CheckboxFooter";
const SignupSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email"),
});
export default function NewsletterFooter() {
    const [dataNewsletter, setDataNewsletter] = useState({});
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [termsFooter, setTermsFooters] = useState(false);
    const [termsError, setTermsError] = useState(false);
    // const [errorTerms, setErrorTerms] = useState(false);
    function validateEmail(dataNewsletter) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const email = dataNewsletter.email;
        return re.test(String(email).toLowerCase());
    }
    return (
        <div>
            {isSuccess ? (
                <h1>¡Gracias por suscribirte!</h1>
            ) : (
                <Formik
                    initialValues={{
                        email: "",
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={(values) => {
                        window.dataLayer.push({
                            event: "GAEvent",
                            event_category: "Newsletter",
                            event_action: "Click",
                            event_label: `Submit`,
                            interaction: true,
                            component_name: "boton_registrarme",
                        });
                        if (values.email !== "") {
                            if (validateEmail) {
                                if (termsFooter === true) {
                                    setDataNewsletter(values);
                                    // console.log("valuues", values);
                                    setIsSuccess(true);
                                    window.dataLayer.push({
                                        event: "GAEvent",
                                        event_category: "Newsletter",
                                        event_action: "Click",
                                        event_label: `Success`,
                                        interaction: true,
                                        component_name: "boton_registrarme",
                                    });
                                } else {
                                    setTermsError(true);
                                    window.dataLayer.push({
                                        event: "GAEvent",
                                        event_category: "Newsletter",
                                        event_action: "Error",
                                        event_label: `Falta aceptar terminos`,
                                        interaction: true,
                                        component_name: "boton_registrarme",
                                    });
                                }
                            } else {
                                setIsError(true);
                                window.dataLayer.push({
                                    event: "GAEvent",
                                    event_category: "Newsletter",
                                    event_action: "Error",
                                    event_label: `Falta completar email`,
                                    interaction: true,
                                    component_name: "boton_registrarme",
                                });
                            }
                        }
                        setIsError(true);
                    }}
                >
                    <Form className={styles.formFooterContainer}>
                        <div className={styles.formFirst}>
                            <Field
                                id="email"
                                name="email"
                                placeholder={
                                    isError
                                        ? "El email no es válido"
                                        : "Ingresá tu mail para recibir novedades"
                                }
                                type="email"
                                className={styles.inputFooter}
                            />

                            <button
                                className={styles.submitFooter}
                                type="submit"
                            >
                                <img alt="icon" src={SubmitIcon} />
                            </button>
                        </div>
                        <div className={styles.formSecond}>
                            <div className={styles.checkboxContainer}>
                                {termsError && (
                                    <h1>
                                        Debes aceptar los términos y condiciones
                                    </h1>
                                )}
                                <CheckboxFooter
                                    termsFooter={termsFooter}
                                    setTermsFooter={setTermsFooters}
                                />
                            </div>
                            {/* {errorTerms && !terms ? (
                <div className={styles.errorContainer}>
                  Los terminos y condiciones deben ser aceptados.
                </div>
              ) : null} */}
                        </div>
                    </Form>
                </Formik>
            )}
        </div>
    );
}
