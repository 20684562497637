import React from "react";
import styles from "./../styles/section.module.scss";
import { styled } from "@stitches/react";
import { CheckIcon } from "@radix-ui/react-icons";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import useDeviceDetect from "./../hooks/useDevice";

const StyledCheckbox = styled(CheckboxPrimitive.Root, {
  all: "unset",
  borderRadius: 4,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "transparent",
  // boxShadow: `0 2px 10px black`,
  border: `1.3px solid black`,
  "&:hover": { backgroundColor: "#f3ebdf" },
});

const StyledIndicator = styled(CheckboxPrimitive.Indicator, {
  color: "black",
});

// Exports
const Checkbox = StyledCheckbox;
const CheckboxIndicator = StyledIndicator;

// Your app...
const Flex = styled("div", { display: "flex" });

export default function CheckboxFooter({ termsFooter, setTermsFooter }) {
  const { isMobile } = useDeviceDetect();
  const handlerCheck = () => {
    setTermsFooter(!termsFooter);
  };
  return (
    <Flex
      css={{
        alignItems: "center",
        width: "100%",
        marginTop: "5px",
        justifyContent: "center",
      }}
    >
      <Checkbox
        checked={termsFooter}
        terms={termsFooter}
        id="c1"
        value={termsFooter}
        onClick={handlerCheck}
        name="checked"
        style={{
          height: isMobile ? "11px" : "18px",
          width: isMobile ? "11px" : "18px",
        }}
      >
        <CheckboxIndicator>
          <CheckIcon />
        </CheckboxIndicator>
      </Checkbox>
      <div htmlFor="c1" className={styles.checkboxFooter}>
        <h3 className={styles.termsTextFooter}>
          He leído y acepto bases y condiciones,&nbsp;
          <a
            className={styles.linkFooter}
            target="_blank"
            href="/TerminosYCondiciones27.pdf"
          >
            términos y condiciones
          </a>
          ,&nbsp;
          <a
            className={styles.linkFooter}
            target="_blank"
            href="/PoliticasPrivacidad27.pdf"
          >
            políticas de privacidad
          </a>{" "}
          y activaciones de Marketing.
        </h3>
      </div>
    </Flex>
  );
}
